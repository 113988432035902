'use client'

import { CookiesContext } from 'cookies/context/CookiesContext'
import { useContext } from 'react'

export const useCookiesContext = () => {
	const cookiesContext = useContext(CookiesContext)

	if (!cookiesContext) {
		throw new Error('useCookiesContext must be used within a CookiesProvider')
	}

	return cookiesContext
}
