'use client'

import { LayoutStateFeatureFlagContext } from 'feature-flags/context/LayoutFeatureFlagProvider/LayoutFeatureFlagProvider'
import type { FeatureFlag, FeatureFlagMap } from 'feature-flags/types'
import { useContext } from 'react'

import type { featureFlagKeys } from '../../constants/featureFlags'

export function useFeatureFlag<T extends FeatureFlag>(
	featureFlagId: featureFlagKeys
): T {
	const value = useContext<FeatureFlagMap | undefined>(
		LayoutStateFeatureFlagContext
	)

	if (!value) {
		throw new Error(
			'useFeatureFlag must be used within a LayoutFeatureFlagProvider'
		)
	}

	if (!value[featureFlagId]) {
		throw new Error(
			`Feature flag ${featureFlagId} not found in LayoutFeatureFlagProvider`
		)
	}
	return value[featureFlagId] as T
}
