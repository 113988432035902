'use client'

import { useCookiesCompliance } from 'cookies/hooks/useCookiesCompliance'
import { useCookiesComplianceLevel } from 'cookies/hooks/useCookiesComplianceLevel'
import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { ButtonStyle, ButtonType } from 'cookies/types'
import { Button } from 'fukku/Button'
import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { cx } from 'utils/cx'

import stylesContent from '../CookiesContent.module.scss'
import styles from './CookiesNotice.module.scss'
import text from 'fukku/styles/texts.module.scss'

const testId = 'cookies.button.acceptAll'

export const CookiesNotice = () => {
	const { t } = useLabels()
	const { buttonsConfiguration } = useCookiesContext()
	const { rejectAllCookies, allowAllCookies } = useCookiesCompliance()
	const { goToFirstLevel } = useCookiesComplianceLevel()

	const { settings, rejectAll } = buttonsConfiguration

	const placeholderValues = {
		linkHere: `/privacy-policy#cookies`, // TODO delete after publishing key in all env
		PrivacyPolicyCookies: `/privacy-policy#cookies`,
	}

	const isLink = (button: { type?: ButtonType; style?: ButtonStyle }) => {
		return button.type === ButtonType.Link
	}

	const addPrimaryStyle = (button: {
		type?: ButtonType
		style?: ButtonStyle
	}) => {
		return (
			button.type === ButtonType.Button &&
			button.style !== ButtonStyle.Secondary &&
			stylesContent.button
		)
	}

	const hasCorrectType = (
		button: {
			type?: ButtonType
			style?: ButtonStyle
		},
		type: ButtonType
	) => {
		return button.type === type
	}

	return (
		<div className={styles.cookiesNotice}>
			<div className={styles.cookiesNoticeContent}>
				{rejectAll.firstLevel && hasCorrectType(rejectAll, ButtonType.Link) && (
					<button
						className={cx(styles.rejectFirstLevel, text.bodyMRegularUnderline)}
						onClick={rejectAllCookies}
						data-testid='cookies.button.rejectAll'
					>
						{t('cookies.configuration.continueWithoutAccept.link')}
					</button>
				)}
				<div className={styles.cookiesPolicy}>
					<ClientLabel
						placeholderValues={placeholderValues}
						linkProps={{ className: styles.link }}
					>
						{t('cookies.banner.bodycopy')}
					</ClientLabel>
				</div>

				<div
					className={cx(
						styles.cookiesFooter,
						hasCorrectType(settings, ButtonType.Button) &&
							styles.centerCookiesFooter
					)}
				>
					<Button
						secondary={
							(settings.type === ButtonType.Button &&
								settings.style === ButtonStyle.Secondary) ||
							isLink(settings)
						}
						border={!isLink(settings)}
						className={cx(
							addPrimaryStyle(settings),
							isLink(settings) && styles.buttonLink
						)}
						onClick={goToFirstLevel}
						data-testid='cookies.button.settings'
					>
						{t('cookies.banner.configuration.button')}
					</Button>
					{rejectAll.firstLevel &&
						hasCorrectType(rejectAll, ButtonType.Button) && (
							<Button
								secondary={rejectAll.style === ButtonStyle.Secondary}
								className={cx(addPrimaryStyle(rejectAll))}
								onClick={rejectAllCookies}
								data-testid='cookies.button.rejectAll'
							>
								{t('cookies.configuration.rejectAll.button')}
							</Button>
						)}
					<Button
						className={stylesContent.button}
						id={testId}
						onClick={allowAllCookies}
						data-testid={testId}
					>
						{t('cookies.banner.accepAll.button')}
					</Button>
				</div>
			</div>
		</div>
	)
}
