'use client'

import type { FeatureFlagMap } from 'feature-flags/types'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useMemo,
	useState,
} from 'react'

export const LayoutStateFeatureFlagContext = createContext<
	FeatureFlagMap | undefined
>(undefined)
export const LayoutSetStateFeatureFlagContext = createContext<
	Dispatch<SetStateAction<FeatureFlagMap>>
>(() => {
	/* do nothing */
})

export function LayoutFeatureFlagProvider({
	children,
	featureFlags,
}: {
	children: React.ReactNode
	featureFlags: FeatureFlagMap
}) {
	const [state, setState] = useState<FeatureFlagMap>()

	const layoutAndPageFeatureFlags = useMemo(
		() => ({ ...state, ...featureFlags }),
		[featureFlags, state]
	)

	return (
		<LayoutStateFeatureFlagContext.Provider value={layoutAndPageFeatureFlags}>
			<LayoutSetStateFeatureFlagContext.Provider
				value={setState as Dispatch<SetStateAction<FeatureFlagMap>>}
			>
				{children}
			</LayoutSetStateFeatureFlagContext.Provider>
		</LayoutStateFeatureFlagContext.Provider>
	)
}
