import {
	type PreferencesCookiesCategories,
	PreferencesCookiesCategoriesValue,
} from 'cookies/types'
import type { Session } from 'session/src/types/session'

import { isGTMConsent } from '../../../types/GTM'

const GRANTED = PreferencesCookiesCategoriesValue.true
const DENIED = PreferencesCookiesCategoriesValue.false

interface SendConsentModeProps {
	categories: PreferencesCookiesCategories
	session: Session
}

export const sendConsentMode = ({
	categories,
	session,
}: SendConsentModeProps): void => {
	const adsConsent =
		categories.targeting === GRANTED
			? GRANTED.toLowerCase()
			: DENIED.toLowerCase()

	const shouldUpdate = window.dataLayer.some(
		(event) =>
			isGTMConsent(event) && event[0] === 'consent' && event[1] === 'default'
	)
	window.gtag('consent', shouldUpdate ? 'update' : 'default', {
		ad_storage: adsConsent,
		ad_user_data: adsConsent,
		ad_personalization: adsConsent,
		analytics_storage:
			categories.performance === GRANTED || session.logged
				? GRANTED.toLowerCase()
				: DENIED.toLowerCase(),
	})
}
