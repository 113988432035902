export const getCookie = (name: string): string | undefined => {
	if (typeof document === 'undefined') {
		return undefined
	}

	const cookieValue = document.cookie
		.split('; ')
		.find((cookie) => cookie.startsWith(`${name}=`))

	if (cookieValue) {
		return decodeURIComponent(cookieValue.split('=')[1])
	}

	return undefined
}
