import { getClientProductPriceUrl } from 'product/services/productPrices/getClientPriceUrl'
import type { ProductPrices } from 'product/types/Prices'
import type { Product, ProductColor } from 'product/types/Product'
import type { MasterData } from 'types/masterData'

const productPrincesStore: Record<
	Product['id'],
	Record<ProductColor['id'], ProductPrices>
> = {}

export async function getProductPrices(
	masterData: MasterData,
	productId: string
) {
	if (!productPrincesStore[productId]) {
		const {
			country: { countryISO },
			channel,
		} = masterData
		const pricesResponse = await fetch(
			getClientProductPriceUrl({ countryISO, channel, productId }),
			{ next: { revalidate: 0 } }
		)
		productPrincesStore[productId] = await pricesResponse.json()
	}
	return productPrincesStore[productId]
}
