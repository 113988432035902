'use client'

import { useCookiesFeatureFlag } from 'cookies/hooks/useCookiesFeatureFlag/useCookiesFeatureFlag'
import {
	type ButtonConfigurationType,
	COOKIES_PREFERENCES,
	CookiesBannerStep,
	CookiesSettingsActions,
	type PreferencesCookiesCategories,
} from 'cookies/types'
import { type ReactNode, createContext, useMemo, useRef, useState } from 'react'

import {
	getInitialButtonsConfig,
	getInitialCookiesCategories,
} from '../utils/getInitialCookiesPreferences'

export interface CookiesContextType {
	cookiesConsent: boolean | undefined
	setCookiesConsent: (cookiesConsent: boolean) => void
	step: CookiesBannerStep
	setStep: (step: CookiesBannerStep) => void
	activeCookiePreference: COOKIES_PREFERENCES
	setActiveCookiePreference: (step: COOKIES_PREFERENCES) => void
	categories: PreferencesCookiesCategories
	setCategories: (categories: PreferencesCookiesCategories) => void
	buttonsConfiguration: ButtonConfigurationType
	setButtonsConfiguration: (
		buttonsConfiguration: ButtonConfigurationType
	) => void
	show: boolean
	setShow: (show: boolean) => void
	blockedPageTypes: string[]
	lastAction: CookiesSettingsActions | undefined
	setLastAction: (action: CookiesSettingsActions) => void
}

export interface CookiesProviderProps {
	children: ReactNode
}

export const CookiesContext = createContext<CookiesContextType | null>(null)

export const CookiesProvider = ({
	children,
}: CookiesProviderProps): ReactNode => {
	const bannerConfiguration = useCookiesFeatureFlag()

	// States
	const [cookiesConsent, setCookiesConsent] = useState<boolean | undefined>(
		undefined
	)
	const [step, setStep] = useState<CookiesBannerStep>(
		CookiesBannerStep.initialStep
	)
	const [activeCookiePreference, setActiveCookiePreference] =
		useState<COOKIES_PREFERENCES>(COOKIES_PREFERENCES.privacy)
	const [categories, setCategories] = useState(
		getInitialCookiesCategories(bannerConfiguration)
	)
	const [buttonsConfiguration, setButtonsConfiguration] = useState(
		getInitialButtonsConfig(bannerConfiguration)
	)
	const [show, setShow] = useState(false)
	const blockedPageTypes = useRef(
		bannerConfiguration?.variables?.blockedPageTypes?.pageTypes
	)
	const [lastAction, setLastAction] = useState<
		CookiesSettingsActions | undefined
	>(undefined)

	const values = useMemo(
		() => ({
			cookiesConsent,
			setCookiesConsent,
			step,
			setStep,
			activeCookiePreference,
			setActiveCookiePreference,
			categories,
			setCategories,
			buttonsConfiguration,
			setButtonsConfiguration,
			show,
			setShow,
			lastAction,
			setLastAction,
			blockedPageTypes: blockedPageTypes.current,
		}),
		[
			cookiesConsent,
			setCookiesConsent,
			step,
			setStep,
			activeCookiePreference,
			setActiveCookiePreference,
			categories,
			setCategories,
			buttonsConfiguration,
			setButtonsConfiguration,
			show,
			setShow,
			lastAction,
			setLastAction,
			blockedPageTypes,
		]
	)

	return (
		<CookiesContext.Provider value={values}>{children}</CookiesContext.Provider>
	)
}
