import type { PreferencesCookiesCategoriesValue } from 'cookies/types'

import { CONSENT_COOKIE } from '../constants'
import { getCookie } from './getCookies'

function isGranted(value: PreferencesCookiesCategoriesValue) {
	return value === 'GRANTED' || value === 'NO_CHOICE'
}

export function getCookiesConsent() {
	const rawConsent = getCookie(CONSENT_COOKIE)
	if (rawConsent) {
		const consent = JSON.parse(rawConsent)
		return {
			adsConsent: isGranted(consent.targeting),
			analyticsConsent: isGranted(consent.performance),
			social: isGranted(consent.social),
			functional: isGranted(consent.functional),
			necessary: isGranted(consent.necessary),
		}
	}
	return null
}
