import {
	type ButtonConfigurationType,
	ButtonStyle,
	ButtonType,
	type CookiesBannerVariables,
	type PreferencesCookiesCategories,
	PreferencesCookiesCategoriesValue,
} from 'cookies/types/index'

import { CONSENT_COOKIE } from '../constants'
import { getCookie } from './getCookies'

export const defaultCookiesPreferences = {
	variables: {
		categories: {
			necessary: {
				blocked: true,
				status: true,
			},
			functional: {
				blocked: false,
				status: false,
			},
			performance: {
				blocked: false,
				status: false,
			},
			targeting: {
				blocked: false,
				status: false,
			},
			social: {
				blocked: false,
				status: false,
			},
		},
		buttonsConfiguration: {
			settings: {
				type: ButtonType.Link,
			},
			rejectAll: {
				firstLevel: true,
				type: ButtonType.Button,
				style: ButtonStyle.Secondary,
			},
			acceptAll: {
				type: ButtonType.Button,
				style: ButtonStyle.Primary,
			},
		},
		blockedPageTypes: { pageTypes: [] },
	},
	enabled: true,
}

const getCookieCategoryValue = (
	blocked: boolean,
	status: boolean
): PreferencesCookiesCategoriesValue => {
	if (blocked) {
		return PreferencesCookiesCategoriesValue.disabled
	}

	return status
		? PreferencesCookiesCategoriesValue.true
		: PreferencesCookiesCategoriesValue.false
}

export const getInitialCookiesCategories = (
	cookiesBanner: CookiesBannerVariables
): PreferencesCookiesCategories => {
	if (cookiesBanner) {
		const cookieValueString = getCookie(CONSENT_COOKIE)
		if (cookieValueString) {
			return JSON.parse(cookieValueString)
		}

		const {
			variables: {
				categories: { necessary, functional, performance, targeting, social },
			},
		} = cookiesBanner
		const preferencesCookiesCategories: PreferencesCookiesCategories = {
			necessary: getCookieCategoryValue(necessary.blocked, necessary.status),
			functional: getCookieCategoryValue(functional.blocked, functional.status),
			performance: getCookieCategoryValue(
				performance.blocked,
				performance.status
			),
			targeting: getCookieCategoryValue(targeting.blocked, targeting.status),
			social: getCookieCategoryValue(social.blocked, social.status),
		}

		return preferencesCookiesCategories
	}
	throw new Error('[CookiesBanner] Error getting feature flag variables')
}

export const getInitialButtonsConfig = (
	cookiesBanner: CookiesBannerVariables
): ButtonConfigurationType => {
	if (cookiesBanner) {
		const {
			variables: { buttonsConfiguration },
		} = cookiesBanner

		return buttonsConfiguration
	}
	throw new Error('[CookiesBanner] Error getting feature flag variables')
}
