import { DEFAULT_LOOK_ID } from 'product/constants/constants'
import { getProductPrices } from 'product/services/productPrices/getProductPrices'
import { type MasterData } from 'types/masterData'

const NO_PERSONALIZED = 'no-personalized'

export interface Item {
	productId: string
	colorId: string
	quantity: number
	lookId?: string
	index?: number
	listId?: string
	personalized?: string
	sizeId?: string
	coupon?: string
	seller?: string
	look?: string
	lastUnits?: boolean
	layoutView?: string
}

export interface ItemWithPrices extends Item {
	currentPrice: number
	originalPrice: number
}

export interface EECItem {
	item_id: string
	item_category3: string
	item_category4: boolean
	item_list_id?: string
	item_list_name?: string
	item_variant: string
	index?: number
	quantity: number
	current_price: number
	original_price: number
	last_units?: boolean
	layout_view?: string
	item_category2?: string
	coupon?: string
}

export const getEECItemsAsync = async (
	masterData: MasterData,
	items: Item[]
): Promise<EECItem[]> => {
	const mapItemToEEC = async (item: Item): Promise<EECItem> => {
		const { productId, colorId } = item
		const prices = await getProductPrices(masterData, productId)

		return itemToEEC({
			...item,
			currentPrice: prices?.[colorId]?.price,
			originalPrice:
				prices?.[colorId]?.previousPrices?.originalShop ||
				prices?.[colorId]?.price,
		})
	}

	return Promise.all(items.map(mapItemToEEC))
}

const itemToEEC = ({
	productId,
	sizeId,
	lookId = DEFAULT_LOOK_ID,
	personalized,
	listId,
	colorId,
	index,
	quantity,
	currentPrice,
	originalPrice,
	coupon,
	lastUnits,
	layoutView,
}: ItemWithPrices): EECItem => ({
	index,
	quantity,
	coupon,
	item_id: productId,
	item_category2: sizeId,
	item_category3: lookId,
	item_category4: personalized ? personalized !== NO_PERSONALIZED : false,
	item_list_id: listId,
	item_list_name: listId,
	item_variant: colorId,
	current_price: currentPrice,
	original_price: originalPrice,
	last_units: lastUnits,
	layout_view: layoutView,
})

export const getEECItems = (items: ItemWithPrices[]): EECItem[] =>
	items.map(itemToEEC)
