import { env } from 'utils/envManager'

export const getSessionId = (): Promise<string> =>
	new Promise((resolve) => {
		window.gtag(
			'get',
			env.NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID,
			'session_id',
			(args: string) => resolve(args)
		)
	})
