import { CustomEventTypes } from 'analytics/types/CustomEvent'
import { getDataLayerData } from 'analytics/utils/getDataLayerData/getDataLayerData'
import type { Dispatch, SetStateAction } from 'react'
import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'
import { isNativeApp } from 'webview/constants'
import { sendAppAnalytic } from 'webview/utils/sendAppAnalytic'

import { getSessionId } from '../../../utils/getSessionId/getSessionId'
import { sendDataLayerData } from '../sendDataLayerData/sendDataLayerData'

export const sendPageview = (
	setIsGTMLoaded: Dispatch<SetStateAction<boolean>>,
	masterData: MasterData,
	session: Session
): void => {
	if (isNativeApp()) {
		setIsGTMLoaded(true)
		sendDataLayerData({ masterData, session })
		sendAppAnalytic(
			CustomEventTypes.SCREEN_VIEW,
			getDataLayerData(masterData, session) as unknown as Record<
				string,
				unknown
			>
		)
	} else {
		sendDataLayerData({ masterData, session })
		window.gtag('event', CustomEventTypes.LOAD_DATALAYER, {
			event_callback: async () => {
				window.analytics = {
					...window.analytics,
					sessionId: await getSessionId(),
				}
				setIsGTMLoaded(true)
			},
		})
	}
}
