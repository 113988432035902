'use client'

import { userAgent } from 'next/server'

export function isBot(): boolean {
	if (typeof window === 'undefined') {
		return false
	}

	const { isBot: userIsBot } = userAgent({
		headers: {
			get: () => window.navigator.userAgent,
		} as unknown as Headers,
	})

	return userIsBot
}
