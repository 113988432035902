import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { type BeaconEvent, EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type ItemWithPrices,
	getEECItems,
} from '../../../utils/getEECItems/getEECItems'

export interface MapBeginCheckoutToBeaconProps {
	masterData: MasterData
	session: Session
	payload: BeginCheckoutPayload
}

export interface BeginCheckoutPayload {
	location: string
	items: ItemWithPrices[]
}

export interface BeaconBeginCheckoutEvent {
	name: EECEventType.BEGIN_CHECKOUT
	params: CommonParams & {
		location: string
		items: EECItem[]
	}
}

export const mapBeginCheckoutToBeacon = ({
	masterData,
	session,
	payload,
}: MapBeginCheckoutToBeaconProps): BeaconEvent => ({
	name: EECEventType.BEGIN_CHECKOUT,
	params: {
		...getCommonParams({ masterData, session }),
		location: payload.location,
		items: getEECItems(payload.items),
	},
})
