import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import { getDataLayerData } from '../../../utils/getDataLayerData/getDataLayerData'

interface SendDataLayerData {
	masterData: MasterData
	session: Session
}

export const sendDataLayerData = ({
	masterData,
	session,
}: SendDataLayerData) => {
	window.dataLayer.push(getDataLayerData(masterData, session))
}
