import type { PreferencesCookiesCategories } from 'cookies/types'
import type { Channel } from 'types/masterData'
import { env } from 'utils/envManager'

import { getCookie } from './getCookies'

export const sendConsent = async (
	categories: PreferencesCookiesCategories,
	channel: Channel
) => {
	const payload = {
		user_id: getCookie('client_id'),
		consent: categories,
		channel,
	}

	try {
		await fetch(
			new URL('/ws-cookies-auditor/consent', env.NEXT_PUBLIC_SITE_BASE_URL)
				.href,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
				next: { revalidate: 0 },
			}
		)
	} catch (error) {
		console.error(error)
	}
}
