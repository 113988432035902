export interface PlpDomainData {
	page_title: string
	item_list_name: string
	item_list_id: string
}

export interface PdpDomainData {
	page_title: string
}

/**
 * All custom data type by domain should be added here
 */
export type CustomDomainData = PlpDomainData | PdpDomainData

export const withItemListDomainData = (
	domainData: CustomDomainData | undefined | null
): domainData is PlpDomainData => {
	return (domainData as PlpDomainData)?.item_list_id !== undefined
}
