import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { type BeaconEvent, EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import type { Item } from '../../../utils/getEECItems/getEECItems'
import {
	type EECItem,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapRemoveFromWishlistToBeaconProps {
	masterData: MasterData
	session: Session
	payload: RemoveFromWishlistPayload
}

export interface RemoveFromWishlistPayload {
	item: Item
	location: string
	listId?: string
}

export interface BeaconRemoveFromWishlistEvent {
	name: EECEventType.REMOVE_FROM_WISHLIST
	params: CommonParams & {
		items: EECItem[]
	}
}

export const mapRemoveFromWishlistToBeacon = async ({
	masterData,
	session,
	payload,
}: MapRemoveFromWishlistToBeaconProps): Promise<BeaconEvent> => ({
	name: EECEventType.REMOVE_FROM_WISHLIST,
	params: {
		...getCommonParams({
			masterData,
			session,
			location: payload.location,
			listId: payload.listId,
		}),
		items: await getEECItemsAsync(masterData, [
			{ ...payload.item, listId: payload.listId },
		]),
	},
})
