import type { Channel } from 'types/masterData'

import { combineUrl } from './combineUrl'

type ProductPriceUrlProps = {
	productId: string
	countryISO: string
	channel: Channel
}

export function getClientProductPriceUrl({
	productId,
	countryISO,
	channel,
}: ProductPriceUrlProps): string {
	if (!process.env.NEXT_PUBLIC_SITE_BASE_URL) {
		throw new Error('NEXT_PUBLIC_SITE_BASE_URL is not defined')
	}

	const basePath = `ws-online-orchestrator/v2/prices/products/${productId}`

	const searchParams = new URLSearchParams({
		countryIso: countryISO,
		channelId: channel,
	})

	return combineUrl(
		process.env.NEXT_PUBLIC_SITE_BASE_URL,
		basePath,
		searchParams
	)
}
