import {
	type PreferencesCookiesCategories,
	PreferencesCookiesCategoriesValue,
} from 'cookies/types'

const getCategorieStatus = (
	status: PreferencesCookiesCategoriesValue,
	newStatus: PreferencesCookiesCategoriesValue
): PreferencesCookiesCategoriesValue =>
	status === PreferencesCookiesCategoriesValue.disabled
		? PreferencesCookiesCategoriesValue.disabled
		: newStatus

export const getCategoriesStatus = (
	categories: PreferencesCookiesCategories,
	status: PreferencesCookiesCategoriesValue
): PreferencesCookiesCategories => {
	return Object.entries(categories).reduce(
		(acc, [categorie, value]) => ({
			...acc,
			[categorie]: getCategorieStatus(value, status),
		}),
		{}
	) as PreferencesCookiesCategories
}
