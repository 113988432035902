import { useContext } from 'react'

import {
	AnalyticsContext,
	type AnalyticsContextType,
} from '../../contexts/AnalyticsProvider'

export const useCanSendAnalytics = (): AnalyticsContextType => {
	const analyticsContext = useContext(AnalyticsContext)

	if (!analyticsContext) {
		throw new Error(
			'[AnalyticsProvider] useCanSendAnalytics must be used within a AnalyticsStateProvider'
		)
	}

	return analyticsContext
}
