'use client'

import { CONSENT_COOKIE } from 'cookies/constants'
import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import {
	CookiesSettingsActions,
	PreferencesCookiesCategoriesValue,
} from 'cookies/types/index'
import { sendConsent } from 'cookies/utils/sendCookiesCompilance'
import { setCookie } from 'cookies/utils/setCookies'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { getCategoriesStatus } from '../../utils/getCategoriesStatus'

const COOKIE_LIFETIME = 13

export const useCookiesCompliance = () => {
	const {
		categories,
		setCategories,
		setCookiesConsent,
		setLastAction,
		setShow,
	} = useCookiesContext()
	const { channel } = useMasterData()

	const handleCompliance = (action: CookiesSettingsActions) => {
		setLastAction(action)
		let newCategories = { ...categories }
		if (action === CookiesSettingsActions.allowAll) {
			newCategories = {
				...getCategoriesStatus(
					categories,
					PreferencesCookiesCategoriesValue.true
				),
			}
		}

		if (action === CookiesSettingsActions.rejectAll) {
			newCategories = {
				...getCategoriesStatus(
					categories,
					PreferencesCookiesCategoriesValue.false
				),
			}
		}
		if (action !== CookiesSettingsActions.save) {
			setCategories(newCategories)
		}
		setCookie(CONSENT_COOKIE, JSON.stringify(newCategories), COOKIE_LIFETIME)
		sendConsent(newCategories, channel)
		setCookiesConsent(true)
		setShow(false)
	}

	const allowAllCookies = () =>
		handleCompliance(CookiesSettingsActions.allowAll)

	const rejectAllCookies = () =>
		handleCompliance(CookiesSettingsActions.rejectAll)

	const saveCookies = () => handleCompliance(CookiesSettingsActions.save)

	return { handleCompliance, allowAllCookies, rejectAllCookies, saveCookies }
}
