import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { type BeaconEvent, EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type ItemWithPrices,
	getEECItems,
} from '../../../utils/getEECItems/getEECItems'

export interface MapViewCartToBeaconProps {
	masterData: MasterData
	session: Session
	payload: ViewCartPayload
}

export interface ViewCartPayload {
	location: string
	items: ItemWithPrices[]
	bagItemNumber: number
}

export interface BeaconViewCartEvent {
	name: EECEventType.VIEW_CART
	params: CommonParams & {
		location: string
		items: EECItem[]
		bag_item_number: number
	}
}

export const mapViewCartToBeacon = ({
	masterData,
	session,
	payload,
}: MapViewCartToBeaconProps): BeaconEvent => ({
	name: EECEventType.VIEW_CART,
	params: {
		...getCommonParams({ masterData, session }),
		location: payload.location,
		items: getEECItems(payload.items),
		bag_item_number: payload.bagItemNumber,
	},
})
