import { useEffect } from 'react'

export function useVisibilityChange(handler: () => void) {
	useEffect(() => {
		window.addEventListener('visibilitychange', handler)
		return () => {
			window.removeEventListener('visibilitychange', handler)
		}
	}, [handler])
}
