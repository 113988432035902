import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import {
	type BeaconEvent,
	EECEventType,
	type MngItem,
} from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import type { EECItem, Item } from '../../../utils/getEECItems/getEECItems'
import { getEECItemsAsync } from '../../../utils/getEECItems/getEECItems'

export interface MapRemoveFromCartToBeaconProps {
	masterData: MasterData
	session: Session
	payload: RemoveFromCartPayload
}

export interface RemoveFromCartPayload {
	location: string
	item: Item
	mngItems: MngItem[]
}

export interface BeaconRemoveFromCartEvent {
	name: EECEventType.REMOVE_FROM_CART
	params: CommonParams & {
		location: string
		items: EECItem[]
		mng_items: string
	}
}

export const mapRemoveFromCartToBeacon = async ({
	masterData,
	session,
	payload,
}: MapRemoveFromCartToBeaconProps): Promise<BeaconEvent> => ({
	name: EECEventType.REMOVE_FROM_CART,
	params: {
		...getCommonParams({ masterData, session }),
		items: await getEECItemsAsync(masterData, [payload.item]),
		location: payload.location,
		mng_items: JSON.stringify(payload.mngItems),
	},
})
