import { CookiesBannerStep } from 'cookies/types'

export const isInitialStep = (step: CookiesBannerStep): boolean => {
	return step === CookiesBannerStep.initialStep
}

export const isFirstStep = (step: CookiesBannerStep): boolean => {
	return step === CookiesBannerStep.firstStep
}

export const isSecondStep = (step: CookiesBannerStep): boolean => {
	return step === CookiesBannerStep.secondStep
}
