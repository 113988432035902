import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { type BeaconEvent, EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import type { Item } from '../../../utils/getEECItems/getEECItems'
import {
	type EECItem,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapAddToWishlistToBeaconProps {
	masterData: MasterData
	session: Session
	payload: AddToWishlistPayload
}

export interface AddToWishlistPayload {
	item: Item
	location: string
	listId?: string
}

export interface BeaconAddToWishlistEvent {
	name: EECEventType.ADD_TO_WISHLIST
	params: CommonParams & {
		items: EECItem[]
	}
}

export const mapAddToWishlistToBeacon = async ({
	masterData,
	session,
	payload,
}: MapAddToWishlistToBeaconProps): Promise<BeaconEvent> => ({
	name: EECEventType.ADD_TO_WISHLIST,
	params: {
		...getCommonParams({
			masterData,
			session,
			location: payload.location,
			listId: payload.listId,
		}),
		items: await getEECItemsAsync(masterData, [
			{ ...payload.item, listId: payload.listId },
		]),
	},
})
