import { getCookie } from 'utils/cookies/getCookie'

const GA_VERSION_LENGTH = 6

export const getGaCookieValue = () => {
	const gaCookieValue = getCookie('_ga')
	if (!gaCookieValue) {
		return null
	}
	return gaCookieValue.slice(GA_VERSION_LENGTH)
}
