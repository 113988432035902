import { getCookiesConsent } from 'cookies'
import type { Session } from 'session/src/types/session'
import { env } from 'utils/envManager'
import { isNativeApp } from 'webview/constants'
import { sendAppAnalytic } from 'webview/utils/sendAppAnalytic'

import type { BeaconEvent } from '../../types/EEC'
import { getGaCookieValue } from '../../utils/getGaCookieValue/getGaCookieValue'

const COLLECTOR_URL = '/ws-wa-sqs-collector/ga-events'

export interface BeaconServerEvent {
	consent: {
		ad_user_data: string
		ad_personalization: string
	}
	client_id: string | null
	user_id: string | null
	non_personalized_ads: boolean
	events: BeaconEvent[]
}

export const getBeaconServerEvent = (
	session: Session,
	events: BeaconEvent
): BeaconServerEvent => {
	const adsConsent = getCookiesConsent()?.adsConsent ?? false
	const consentAdsPermission = adsConsent ? 'GRANTED' : 'DENIED'

	return {
		consent: {
			ad_user_data: consentAdsPermission,
			ad_personalization: consentAdsPermission,
		},
		client_id: getGaCookieValue(),
		user_id: session.externalId,
		non_personalized_ads: false,
		events: [events],
	}
}

export const sendBeaconServerEvent = (
	session: Session,
	events: BeaconEvent
): boolean | void | null => {
	if (isNativeApp()) {
		return sendAppAnalytic(
			events.name,
			events.params as Record<string, unknown>
		)
	}

	if (!window.analytics.sessionId) {
		return null
	}
	const body = JSON.stringify(getBeaconServerEvent(session, events))

	return navigator.sendBeacon(
		new URL(COLLECTOR_URL, env.NEXT_PUBLIC_SITE_BASE_URL).href,
		new Blob([body])
	)
}
