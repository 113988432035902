export enum featureFlagKeys {
	AkamaiEndpoints = 'akamai_endpoints',
	BestSellerTag = 'show_pdp_tag_bestseller',
	Chatbot = 'chatbot_toggle',
	Checkout = '_checkout__new_checkout',
	CookiesBanner = 'cookies_banner',
	CountryLanguage = 'all_country_language',
	Discover = 'rollout_discover_by_mango',
	DoubleOptIn = 'double_opt_in',
	EditActivation = 'edit_activation',
	EnableDigitalReturns = 'enable_digital_returns',
	FashionAssistant = 'fashion_assistant',
	Favorites = '_all__favorites',
	FavoritesPage = 'page_favorites',
	GenesisPdp = 'pdp_genesis_activation',
	HeaderInspirationalRelease = 'header_inspirational_release',
	HelpPage = 'page_help',
	HomeA11yHiddenLink = 'homes_a11y_hidden_link',
	/** Used in monolith and /h page to redirect to monolith/genesis */
	HomeMultibrand = 'homes_multibrand_banners',
	/** Used in homepages to enable or disable an specific brand (included multibrand) */
	Homes = 'homes_homebrand',
	LegalConfirmation = 'legal_confirmation',
	LoginPage = 'page_login',
	MangoLikesYouPage = 'page_mango_likes_you',
	MangoLikesYouClubTiers = 'mango_likes_you_club_tiers',
	MyAcountPage = 'page_my_account',
	MyAddressesPage = 'page_my_addresses',
	MyBalancePage = 'page_my_balance',
	MyMangoLikesYou = 'page_my_mango_likes_you',
	MyMangoLikesYouPage = 'page_my_mango_likes_you',
	MyPersonalInfoPage = 'page_my_personal_info',
	MyPurchasesPage = 'page_my_purchases',
	MyRefundsPage = 'page_my_refunds',
	MyReturnsPage = 'page_my_returns',
	MySubscriptionsPage = 'page_my_subscriptions',
	NewsletterFooter = 'newsletter_footer',
	PaymentStoredCards = 'payments_stored_cards',
	HighlightedDiscount = 'pdp_highlighted_discount',
	PlpBannersVasava = '_plp__banners__vasava_active',
	PlpSegmentation = 'plp_genesis_segmentation',
	PlpVersion = '_plp__genesis__activation_versions',
	PrivacyPolicyRegistryForm = 'registry_form_privacy_policy',
	PrivacyPolicyRequestForm = 'privacy_policy_request_form',
	ProductCardOverlayLabel = 'productcard_overlay_label',
	ProductCarousel = 'shopping_cart_product_carousel',
	PromoBanner = 'promo_countdown',
	RegistryPage = 'page_registry',
	ReturnHasCost = 'return_has_cost_since_date',
	SearchResultsPage = 'page_search_results',
	ShoppingCartRecommendationXselling = 'shopping_cart_recommendation_xselling',
	ShoppingCartShowPaymentMethods = 'shopping_cart_show_payment_methods',
	ShowSavedPrice = 'cart_show_saved_price',
	OmnibusExceptionCountries = 'price_omnibus_countries',
	SecondReturnsBlock = 'block_second_returns',
	HomesThrowErrorOnFailingSectionId = 'homes_throw_error_on_failing_section_id',
	ReturnsFeedback = 'enable_returns_feedback',
	PurchaseEventInBack = '_checkout__purchase_event_in_back',
	AfterpayModal = 'afterpay_modal_iframe',
	ShowDiscount = 'price_show_discount',
	PaymentsIdealBanks = 'payments_ideal_banks',
	PaymentsKlarnaCLTV = 'payments_klarna_cltv',
	PaymentsProcessOut = 'payments_processout',
}
