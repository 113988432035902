import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { type BeaconEvent, EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type Item,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapViewItemToBeaconProps {
	masterData: MasterData
	session: Session
	payload: ViewItemPayload
}

interface ViewItemPayload {
	item: Item
}

export interface BeaconViewItemEvent {
	name: EECEventType.VIEW_ITEM
	params: CommonParams & {
		items: EECItem[]
	}
}

export const mapViewItemToBeacon = async ({
	masterData,
	session,
	payload,
}: MapViewItemToBeaconProps): Promise<BeaconEvent> => ({
	name: EECEventType.VIEW_ITEM,
	params: {
		...getCommonParams({ masterData, session }),
		items: await getEECItemsAsync(masterData, [payload.item]),
	},
})
