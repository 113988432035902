'use client'

import { useBeforeUnload } from 'hooks/useBeforeUnload/useBeforeUnload'
import { useVisibilityChange } from 'hooks/useVisibilityChange/useVisibilityChange'
import { usePathname, useSearchParams } from 'next/navigation'
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { useEECEvents } from '../hooks/useEECEvents/useEECEvents'
import type { Item } from '../utils/getEECItems/getEECItems'

interface AnalyticsImpressionsProviderProps {
	children: ReactNode
}

type AnalyticsImpressionsContextType = {
	impressions: Item[]
	addImpression: (impression: Item) => void
}

const MAX_IMPRESSIONS_COUNT = 60

export const AnalyticsImpressionsContext =
	createContext<AnalyticsImpressionsContextType>({
		impressions: [],
		addImpression: () => {},
	})

export const AnalyticsImpressionsProvider = ({
	children,
}: AnalyticsImpressionsProviderProps) => {
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const [impressions, setImpressions] = useState<Item[]>([])

	const impressionsCountRef = useRef(0)

	const { sendViewItemList } = useEECEvents()

	const updateImpressions = (impression: Item, index: number): void => {
		setImpressions((prevImpressions) => {
			return [...prevImpressions, { ...impression, index }]
		})
	}

	const addImpression = (impression: Item): void => {
		impressionsCountRef.current += 1

		updateImpressions(impression, impressionsCountRef.current)
	}

	useBeforeUnload(() => {
		if (impressions.length) {
			sendViewItemList({ items: impressions })
			setImpressions([])
		}
	})

	useVisibilityChange(() => {
		if (impressions.length) {
			sendViewItemList({ items: impressions })
			setImpressions([])
		}
	})

	useEffect(() => {
		if (impressions.length >= MAX_IMPRESSIONS_COUNT) {
			sendViewItemList({ items: impressions })
			setImpressions([])
		}

		// This make the debug easier
		window.analytics = {
			...window.analytics,
			impressions: [...impressions],
		}
	}, [impressions])

	useEffect(() => {
		if (impressions.length) {
			sendViewItemList({ items: impressions })
			setImpressions([])
		}
	}, [pathname, searchParams.toString()])

	const impressionsValue = useMemo(
		() => ({
			impressions,
			addImpression,
		}),
		[impressions]
	)

	return (
		<AnalyticsImpressionsContext.Provider value={impressionsValue}>
			{children}
		</AnalyticsImpressionsContext.Provider>
	)
}
export const useAnalyticsImpressions = (): AnalyticsImpressionsContextType => {
	const impressionsContext = useContext(AnalyticsImpressionsContext)

	if (!impressionsContext) {
		throw new Error(
			'useAnalyticsImpressions must be used within a AnalyticsImpressionsProvider'
		)
	}

	return impressionsContext
}
