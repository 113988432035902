import { getTrafficType } from 'analytics/utils/getTrafficType/getTrafficType'
import { getCookiesConsent } from 'cookies'
import type { Session } from 'session/src/types/session'
import { Environment, type MasterData } from 'types/masterData'
import { withItemListDomainData } from 'types/masterData/domainData/domainData'

import type { CommonParams } from '../../types/CommonParams'

interface GetCommonParamsProps {
	masterData: MasterData
	session: Session
	location?: string
	listId?: string
}

export const getCommonParams = ({
	masterData,
	session,
	location,
	listId,
}: GetCommonParamsProps): CommonParams => {
	const {
		channel,
		country: { countryISO, languageISO },
		environment,
		pageType,
		device,
		custom,
	} = masterData
	const isPro = masterData.environment === Environment.Pro
	const analyticsConsent = getCookiesConsent()?.analyticsConsent ?? false
	const adsConsent = getCookiesConsent()?.adsConsent ?? false

	const email = session.logged ? session.email : undefined

	return {
		...custom,
		environment,
		email,
		location,
		session_id: window.analytics.sessionId,
		ads_consent: adsConsent,
		analytics_consent: analyticsConsent,
		application: channel,
		country_iso: countryISO,
		genesis: true,
		gtm_environment: isPro ? 'live' : 'staging',
		is_app: false,
		is_votf: false,
		language_iso: languageISO,
		page_brand: masterData.brand,
		page_location: window.location.href,
		page_type: pageType,
		platform: device,
		traffic_type: getTrafficType(isPro),
		user_logged: session.logged,
		item_list_id:
			listId ||
			(withItemListDomainData(custom) ? custom?.item_list_id : undefined),
		item_list_name:
			listId ||
			(withItemListDomainData(custom) ? custom?.item_list_name : undefined),
	}
}
