import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type Item,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapViewItemListToBeaconProps {
	masterData: MasterData
	session: Session
	payload: ViewItemListPayload
}

export interface ViewItemListPayload {
	items: Item[]
}

export interface BeaconViewItemListEvent {
	name: EECEventType.VIEW_ITEM_LIST
	params: CommonParams & {
		items: EECItem[]
	}
}

export const mapViewItemListToBeacon = async ({
	masterData,
	session,
	payload,
}: MapViewItemListToBeaconProps): Promise<BeaconViewItemListEvent> => ({
	name: EECEventType.VIEW_ITEM_LIST,
	params: {
		...getCommonParams({ masterData, session }),
		items: await getEECItemsAsync(masterData, payload.items),
	},
})
