'use client'

import type { ReactNode } from 'react'
import { cx } from 'utils/cx'

import styles from './CookiesLayout.module.scss'

interface Props {
	children: ReactNode
}

export const CookiesLayout = ({ children }: Props) => {
	return (
		<div
			className={cx(styles.cookiesLayout, styles.middle)}
			data-testid='cookies.layout'
		>
			{children}
		</div>
	)
}
