import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import type { CommonParams } from '../../../types/CommonParams'
import { EECEventType } from '../../../types/EEC'
import { getCommonParams } from '../../../utils/getCommonParams/getCommonParams'
import {
	type EECItem,
	type Item,
	getEECItemsAsync,
} from '../../../utils/getEECItems/getEECItems'

export interface MapSelectItemToBeaconProps {
	masterData: MasterData
	session: Session
	payload: SelectItemPayload
}

export interface SelectItemPayload {
	item: Item
	location?: string
	listId?: string
}

export interface BeaconSelectItemEvent {
	name: EECEventType.SELECT_ITEM
	params: CommonParams & {
		items: EECItem[]
		location?: string
	}
}

export const mapSelectItemToBeacon = async ({
	masterData,
	session,
	payload,
}: MapSelectItemToBeaconProps): Promise<BeaconSelectItemEvent> => ({
	name: EECEventType.SELECT_ITEM,
	params: {
		...getCommonParams({ masterData, session, listId: payload.listId }),
		location: payload.location,
		items: await getEECItemsAsync(masterData, [
			{ ...payload.item, listId: payload.listId },
		]),
	},
})
