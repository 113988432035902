'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { CookiesBannerStep } from 'cookies/types'

export const useCookiesComplianceLevel = () => {
	const { setStep } = useCookiesContext()

	function goToFirstLevel() {
		setStep(CookiesBannerStep.firstStep)
	}

	function goToSecondLevel() {
		setStep(CookiesBannerStep.secondStep)
	}

	return { goToFirstLevel, goToSecondLevel }
}
